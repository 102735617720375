import { APIError, APIResource, APIResponse } from "../utils/APIResource";
import config from "../config/config";

export default class UserService {
  static login(params) {
    let grant_type = "password";

    return APIResource.post(
      `oauth/token`,
      `grant_type=${grant_type}&username=${params.username}&password=${params.password}`,
      {
        headers: {
          Authorization: `Basic ${config.api.authBearer}`,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
      .then(resp => {
        return new APIResponse(resp);
      })
      .catch(error => {
        return new APIError(error);
      });
  }

  static logout() {
    return APIResource.post("/v1/logout")
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static me() {
    return APIResource.get("/v1/employee/me").then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }
  static policy() {
    return APIResource.get("/v1/employee/policy").then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }

  static getEmployee(personId) {
    return APIResource.get(`/v1/employee/${personId}/detail`).then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }

  static getBannerList(personId) {
    return APIResource.get(`/v1/employee/${personId}/banners`).then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }

  static forgotPassword(params) {
    return APIResource.post(
      `/v1/open/pwd`,
      {
        username: params.username
      },
      {}
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static refreshToken(token) {
    let grant_type = "refresh_token";

    return APIResource.post(
      `/oauth/token?grant_type=${grant_type}&refresh_token=${token}`,
      {},
      {
        headers: {
          Authorization: `Basic ${config.api.authBearer}`
        }
      }
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static CIAMLogin(params) {
    let grant_type = "password";

    console.log("hsm login");
    return APIResource.post(
      `oauth/token`,
      `grant_type=${grant_type}&username=${params.username}&password=${params.password}`,
      {
        headers: {
          Authorization: `Basic ${config.api.authBearer}`,
          "x-auth-type": "CIAM",
          "x-auth-random-key": params.randomKey,
          "x-auth-tx-id": params.txnId,
          "x-auth-app": config.app.type,
          "x-auth-cnonce": params.cnonce,
          "x-auth-cnonce-hash": params.chash
        }
      }
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getRSA(params) {
    return APIResource.get("/v1/open/auth/rsa", {
      headers: {
        "x-auth-app": config.app.type
      }
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getOtp(params) {
    return APIResource.get("/v1/auth/otp", {
      headers: {
        "x-auth-type": "CIAM",
        "x-auth-random-key": params.randomKey,
        "x-auth-tx-id": params.txnId,
        "x-auth-app": config.app.type
      }
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getResetPasswordOTP(code) {
    return APIResource.post("/v1/open/pwd/otp", {
      code: code
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getResetLinkValidity(code) {
    return APIResource.get(`/v1/open/pwd/${code}/status`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.resolve(new APIError(error));
      });
  }

  static putResetPasswordOTP(params) {
    return APIResource.put(
      "/v1/open/pwd",
      {
        code: params.code,
        otp: params.otp,
        new_password: params.new_password,
        confirm_password: params.confirm_password
      },
      {
        headers: {
          Authorization: `Basic ${config.api.authBearer}`,
          "x-auth-type": "CIAM",
          "x-auth-random-key": params.randomKey,
          "x-auth-tx-id": params.txnId,
          "x-auth-app": config.app.type
        }
      }
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static postOTP(params) {
    return APIResource.post(
      "/v1/auth/otp/",
      {
        otp: params.otp
      },
      {
        headers: {
          "x-auth-type": "CIAM",
          "x-auth-random-key": params.randomKey,
          "x-auth-tx-id": params.txnId,
          "x-auth-app": config.app.type
        }
      }
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static agreeTnc(params) {
    return APIResource.post("/v1/auth/tc", {
      marketing_consent: params.marketingConsent,
      tnc: params.tnc
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getGaPolicyInfo(policy_no) {
    return APIResource.get(`/v1/policy/gaPolicy/${policy_no}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getSumAssured(policy_no) {
    return APIResource.get(`/v1/policy/${policy_no}/getSumAssured`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static emulateLogin(emulateRef) {
    let grant_type = "password";
    return APIResource.post(
      `oauth/token`,
      `grant_type=${grant_type}&username=${emulateRef}&password=${emulateRef}`,
      {
        headers: {
          Authorization: `Basic ${config.api.authBearer}`,
          "x-auth-type": "EMULATE",
          "x-auth-app": config.app.type
        }
      }
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static emulateLoginV2(emulateRef) {
    return APIResource.post(
      `/v1/emulate/generate-token`,
      `emulateRef=${emulateRef}`
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
