import Login from "../containers/Login/Login";
import ResetPassword from "../containers/Login/ResetPassword";

//Authenticated
import Enums from "../utils/Enums";
import Auth from "../containers/Auth";
import FindNearMe from "../containers/Auth/Clinic/Search/FindNearMe";
import FindADoctor from "../containers/Auth/FindADoctor/FindADoctor";

import Dashboard from "../containers/Auth/Dashboard";
import DashboardMain from "../containers/Auth/Dashboard/Dashboard";
import BenefitDetail from "../containers/Auth/Dashboard/BenefitDetail";

import MyClaims from "../containers/Auth/MyClaims/MyClaims";
import MyBenefits from "../containers/Auth/MyBenefits/MyBenefits";
import MyInfo from "../containers/Auth/MyInfo/MyInfo";
import assets from "../assets";

import EmployeeBenefit from "../containers/Auth/EmployeeBenefit";
import EmployeeBenefitMain from "../containers/Auth/EmployeeBenefit/Main";
import MyBenefitDetail from "../containers/Auth/EmployeeBenefit/BenefitDetail";

//TODO: to move pages out of _Placeholder as we build the actual
import NotFound from "../containers/Auth/_Placeholder/NotFound";
import MyDocuments from "../containers/Auth/MyDocuments";
import TncPage from "../containers/TncPage";
import MaintenancePage from "../containers/MaintenancePage";
import ClaimTncPage from "../containers/Auth/MyClaims/ClaimTermAndConditionsPage";
import EmulatePage from "../containers/Emulate/EmulatePage";

const routes = [
  {
    path: "/",
    exact: true,
    component: Login
  },
  {
    path: "/resetPassword",
    component: ResetPassword
  },
  {
    path: "/maintenance",
    component: MaintenancePage
  },
  {
    path: "/TermsAndConditions",
    exact: true,
    component: TncPage
  },
  {
    path: "/claimTermAndConditions",
    exact: true,
    component: ClaimTncPage
  },
  {
    path: "/emulate/:ref",
    component: EmulatePage
  },
  {
    path: "/auth",
    component: Auth,
    routes: [
      {
        path: "/auth/dashboard",
        name: "Dashboard",
        component: Dashboard,
        access: Enums.SCOPE_TYPE.DASHBOARD_EMP_R,
        menu: {
          show: true,
          image: assets.menuIcons.dashboard
        },
        routes: [
          {
            path: "/auth/dashboard",
            name: "Dashboard",
            exact: true,
            access: Enums.SCOPE_TYPE.DASHBOARD_EMP_R,
            component: DashboardMain
          },
          {
            path: "/auth/dashboard/details/:type",
            name: "Benefit Detail",
            access: Enums.SCOPE_TYPE.DASHBOARD_EMP_R,
            component: BenefitDetail
          }
        ]
      },
      {
        path: "/auth/myInfo",
        name: "My Info",
        component: MyInfo
      },
      {
        path: "/auth/myBenefits",
        name: "My Coverage",
        component: MyBenefits,
        access: Enums.SCOPE_TYPE.INS_COV_EMP_R,
        menu: {
          show: true,
          image: assets.menuIcons.insuranceCoverage
        }
      },
      {
        path: "/auth/clinics/search",
        name: "Find a Doctor",
        access: Enums.SCOPE_TYPE.DOCTOR_R,
        component: FindADoctor,
        menu: {
          show: true,
          image: assets.menuIcons.doctor
        }
      },
      {
        path: "/auth/myClaims",
        name: "My Claim",
        component: MyClaims,
        access: Enums.SCOPE_TYPE.CLAIM_R,
        menu: {
          show: true,
          image: assets.menuIcons.claim
        }
      },
      {
        path: "/auth/employeeBenefit",
        name: "My Benefits",
        component: EmployeeBenefit,
        access: Enums.SCOPE_TYPE.EMP_MYBENEFIT_R,
        menu: {
          show: true,
          validate: {
            employeeBenefit: true
          },
          image: assets.menuIcons.employeeBenefits
        },
        routes: [
          {
            path: "/auth/employeeBenefit",
            name: "Benefits",
            exact: true,
            component: EmployeeBenefitMain,
            access: Enums.SCOPE_TYPE.EMP_MYBENEFIT_R
          },
          {
            path: "/auth/employeeBenefit/details/:type",
            name: "Benefit Detail",
            component: MyBenefitDetail,
            access: Enums.SCOPE_TYPE.EMP_MYBENEFIT_R
          }
        ]
      },
      {
        path: "/auth/myDocuments",
        name: "Documents",
        component: MyDocuments,
        access: Enums.SCOPE_TYPE.DOCUMENT_EMP_R,
        menu: {
          show: true,
          image: assets.menuIcons.documents
        }
      },
      {
        path: "/auth/clinics/find-near-me",
        name: "Find Clinic Near Me",
        component: FindNearMe,
        access: Enums.SCOPE_TYPE.DOCTOR_R,
        menu: {
          show: false
        }
      }
    ]
  },
  {
    component: NotFound
  }
];

export default routes;
