import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { appActions, userActions } from "../../actions";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import HeaderPlain from "../../components/HeaderPlain";
import PopupNotice from "../../components/PopupNotice";
import { withStyles } from "@mui/styles";
import CiamResponseCode from "../../utils/CiamResponseCode";

import Enums from "../../utils/Enums";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import TncDialog from "./tncDialog";
import OtpDialog from "./OtpDialog";
import Util from "../../utils/Util";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import config from "../../config/config";

import AlertTopBar from "../../components/AlertTopBar";

const AFTERLOGIN = "/auth/dashboard";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOTP: false,
      username: "",
      password: "",
      showForgotPassword: false,
      showFirstTimeLogin: false,
      firstTimeLoginSubmitting: false,
      firstTimeLoginError: null,
      isLoggingIn: false,
      showOTPDesc: false,
      isShowError: false,
      submitOTPErrorMessage: "",
      ciamErrorCode: ""
    };
  }

  LOGIN_EVENT = "Login";

  GOOGLE_ANALYTICS_DETAILS = {
    MODULE: {
      GENERAL: "General"
    },
    FEATURE: {
      LOGIN: "Login"
    },
    JOURNEY: {
      LOGIN: "login"
    },
    EVENT: {
      LOGIN: "login"
    },
    EVENT_DETAILS: {
      LOGIN: "login"
    }
  };

  componentDidMount() {
    if (this.props.user.error) {
      this.props.user.error = null;
    }
  }

  componentWillMount() {
    if (this.props.user.loggedIn) {
      if (this.props.history.location.pathname === "/") {
        // logged in go to auth
        this.props.history.push(AFTERLOGIN);
      } else this.props.history.goBack(); //prevent logged in go to login page
    } else {
      this.props.appActions.reset();
    }
  }

  loginClick = () => {
    if (this.state.username.trim() === "") return;
    this.setState({ isLoggingIn: true });
    this.doCIAMLogin();
  };

  getEpochTime = () => {
    return Math.floor(new Date().getTime() / 1000);
  };

  getGoogleTagManagerDataLayer = () => {
    return window.dataLayer || [];
  };

  handleEventDetailsTracking = eventDetails => {
    this.getGoogleTagManagerDataLayer().push({
      ...eventDetails,
      start_time: this.getEpochTime()
    });
  };

  handleLoginTracking = () => {
    this.handleEventDetailsTracking({
      module: this.GOOGLE_ANALYTICS_DETAILS.MODULE.GENERAL,
      feature: this.GOOGLE_ANALYTICS_DETAILS.FEATURE.LOGIN,
      journey: this.GOOGLE_ANALYTICS_DETAILS.JOURNEY.LOGIN,
      event: this.GOOGLE_ANALYTICS_DETAILS.EVENT.LOGIN,
      event_detail: this.GOOGLE_ANALYTICS_DETAILS.EVENT_DETAILS.LOGIN,
      entry_point: null,
      start_time: this.getEpochTime()
    });
  };

  doCIAMLogin = () => {
    const { CIAMLogin, getRSA } = this.props;

    getRSA(this.state.username.trim().toLowerCase(), this.state.password)
      .then(
        resp => {
          if (resp.success) {
            var myObjectInstance = new PACSCryptography(resp.data.public_key);
            var encryptedPW = myObjectInstance.encryptPassword(
              this.state.password
            );

            this.setState({
              public_key: resp.data.public_key,
              tx_id: resp.data.tx_id,
              random_key: resp.data.random_key,
              encrypted: encryptedPW,
              rsaErrorMessage: null,
              isShowError: false
            });
            const cnonce = Util.generateUUID();
            const chash = Util.getCHash(resp.data.public_key, cnonce);
            //do login
            return CIAMLogin(
              this.state.username.trim().toLowerCase(),
              this.state.encrypted,
              this.state.random_key,
              this.state.tx_id,
              cnonce,
              chash
            );
          }
        },
        error => {
          console.log("RSA Error : ", error);
          if (error.code !== 500) {
            this.setState({
              rsaErrorMessage: error.message || error.defaultMessage
            });
          }
          return Promise.reject(error);
        }
      )
      .then(
        response => {
          if (response.success) {
            this.setState({ otpRes: "", showOTPDesc: false });
            this.setState({ showOTP: true }); //open OTP dialog for Sales only
          }
        },
        error => {
          //TODO: Display some error messages using toast or something
          console.error("Login error:", error.customMessage);
          this.setState({ isLoggingIn: false });
        }
      );
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      [name]: value
    });
  };

  handleCloseOtpDialog(otpMessage) {
    const subOtpErrMess =
      typeof otpMessage === "boolean" && otpMessage
        ? this.state.submitOTPErrorMessage
        : "";

    this.setState({
      showOTP: false,
      submitOTPError: false,
      submitOTPErrorMessage: subOtpErrMess,
      isLoggingIn: false,
      ciamErrorCode: ""
    });
  }

  enterAndSubmit = event => {
    if (event.key === "Enter") {
      this.loginClick();
    }
  };

  handleOpenCloseForgotPassword() {
    this.setState({ showForgotPassword: !this.state.showForgotPassword });
  }

  handleSubmitOTP(otp) {
    const { encrypted, random_key, tx_id } = this.state;
    this.props.postOTP(otp, encrypted, random_key, tx_id).then(
      response => {
        if (response.success) {
          this.props.updateToken(response.data);
          this.handleCloseOtpDialog();
          const tokenObj = Util.jwt_decode(response.data.access_token);
          //Fist time log for HR, show T & C
          if (tokenObj.scope.includes(Enums.SCOPE_TYPE.TC_W)) {
            this.setState({ showFirstTimeLogin: true });
          } else {
            //Initialize the product engine
            this.handleLoginSuccess(AFTERLOGIN);
          }
        }
      },
      error => {
        this.setState({
          submitOTPError: true,
          submitOTPErrorMessage: error.customMessage,
          ciamErrorCode: error.message
        });
      }
    );
  }

  handleAgreeTnc = (marketingConsent, tnc) => {
    marketingConsent = marketingConsent ? marketingConsent : false;
    this.props.agreeTnc({ marketingConsent, tnc }).then(
      response => {
        this.setState(
          {
            firstTimeLoginSubmitting: false,
            showFirstTimeLogin: false
          },
          () => {
            this.handleLoginSuccess(AFTERLOGIN);
          }
        );
      },
      error => {
        console.error("Agree TNC error:", error);
        this.setState({ isLoggingIn: false });
      }
    );
  };

  handleLoginSuccess = url => {
    this.handleLoginTracking(); //for google analytics fro login button.
    this.setState({ isLoggingIn: false });
    this.props.history.push(url);
  };

  render() {
    const { classes, user } = this.props;
    const {
      submitOTPErrorMessage,
      isShowError,
      showOTP,
      showForgotPassword,
      showFirstTimeLogin,
      firstTimeLoginSubmitting,
      firstTimeLoginError,
      isLoggingIn,
      ciamErrorCode
    } = this.state;

    let errorCustomMessage = user.error
      ? user.error.customMessage || user.error.defaultMessage
      : submitOTPErrorMessage;

    const errorCodesToHandle = [
      CiamResponseCode.OTP_EXPIRED.code,
      CiamResponseCode.MAX_OTP_FAILED.code,
      CiamResponseCode.OTP_EXPIRED.code_old,
      CiamResponseCode.FORGEROCK_ERROR.code
    ];
    if (errorCodesToHandle.includes(ciamErrorCode)) {
      this.handleCloseOtpDialog(true);
      errorCustomMessage = submitOTPErrorMessage;
      this.setState({ isShowError: true });
    }
    return (
      <div className="login">
        {config.appVersionEnabled && (
          <div>
            <Typography
              variant="body2"
              className={
                config.bannerEnabled == true
                  ? classes.version
                  : classes.versionWithoutBanner
              }
            >
              {config.appVersionNumber}
            </Typography>
          </div>
        )}
        {config.bannerEnabled && (
          <div
            className={
              config.appVersionEnabled == true
                ? classes.bannerDisplay
                : classes.bannerDisplayWithoutVersion
            }
          >
            <AlertTopBar
              displayLogo={config.logoDisplay}
              type="tip"
              title={config.bannerTitle}
              listDescription={config.bannerDetails}
            />
          </div>
        )}
        <HeaderPlain />
        <div
          className={
            config.bannerEnabled == true
              ? classes.bannerWrapper
              : classes.wrapper
          }
        >
          <Paper className={classes.root} elevation={2}>
            <Typography variant="title" className={classes.titleBoxText}>
              Log into PRUworks Employee Portal
            </Typography>
            <br />
            {user.error || isShowError ? (
              <Typography variant="subheading" className={classes.errorMessage}>
                {errorCustomMessage}
              </Typography>
            ) : (
              " "
            )}
            <br />
            <TextField
              fullWidth
              id="username"
              label="Enter Username"
              placeholder="Enter Username"
              value={this.state.username}
              margin="normal"
              onChange={this.handleInputChange}
              onKeyPress={this.enterAndSubmit}
            />
            <br />
            <TextField
              fullWidth
              id="password"
              label="Enter Password"
              placeholder="Enter Password"
              type="password"
              value={this.state.password}
              margin="normal"
              autoComplete="new-password"
              onChange={this.handleInputChange}
              onKeyPress={this.enterAndSubmit}
            />
            <br />
            {/* Fade In spinner when getting OTP */}
            <Fade
              in={isLoggingIn}
              style={{
                transitionDelay: isLoggingIn ? "40ms" : "0ms"
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={this.loginClick}
              disabled={isLoggingIn}
            >
              LOG IN
            </Button>

            <br />
            <br />
            <Button
              className={classes.forgotPassword}
              onClick={this.handleOpenCloseForgotPassword.bind(this)}
            >
              Forgot password
            </Button>
          </Paper>
        </div>

        <OtpDialog
          show={showOTP}
          submitError={this.state.submitOTPError}
          description={
            this.state.showOTPDesc ? (
              <span>
                An OTP has been sent to your registered Email Id and registered
                Mobile Number. <br />
                Email Id: <b>{this.state.otpRes.emailId}</b>
                <br />
                Mobile Number: <b>{this.state.otpRes.contactNo}</b>
                <br />
              </span>
            ) : (
              ""
            )
          }
          submitErrorMessage={this.state.submitOTPErrorMessage}
          submitHandler={this.handleSubmitOTP.bind(this)}
          closeHandler={this.handleCloseOtpDialog.bind(this)}
        />

        <ForgotPasswordDialog
          show={showForgotPassword}
          forgotPassword={this.props.forgotPassword}
          closeHandler={this.handleOpenCloseForgotPassword.bind(this)}
        />

        <TncDialog
          open={showFirstTimeLogin}
          submitting={firstTimeLoginSubmitting}
          error={firstTimeLoginError}
          handleAgree={this.handleAgreeTnc}
        />
        {config.popUpLoginMessageEnabled && (
          <PopupNotice message={config.popUpLoginMessage} />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  wrapper: {
    position: "absolute",
    margin: "auto",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 300,
    height: 310,
    textAlign: "center"
  },
  bannerWrapper: {
    position: "flex",
    margin: "auto",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 300,
    height: 310,
    textAlign: "center"
  },
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30
  },
  forgot: {
    textAlign: "center",
    marginTop: 20
  },
  errorMessage: {
    marginBottom: theme.spacing.unit,
    color: theme.palette.error.main
  },
  bannerDisplay: {
    maxWidth: "90%",
    marginLeft: "5%",
    marginBottom: "5%"
  },
  bannerDisplayWithoutVersion: {
    marginTop: "100px",
    maxWidth: "90%",
    marginLeft: "5%",
    marginBottom: "5%"
  },
  version: {
    paddingTop: "5%",
    paddingRight: "5%",
    textAlign: "right",
    opacity: "50%"
  },
  versionWithoutBanner: {
    paddingTop: "5%",
    paddingRight: "5%",
    paddingBottom: "10%",
    textAlign: "right",
    opacity: "50%"
  }
});

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
